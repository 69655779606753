<template lang="pug">
.input-wrapper(:class="{ ...wrapperClasses, row: sideLabel !== 'default' }")
  slot(name="label" v-if="label || $slots.label")
    label.input-label(v-if="label" :for="id" :class="labelClasses") {{ label }}
  .input-wrapper-col(:class="{ col: sideLabel !== 'default' }")
    .input-element-wrapper(
      :class="{ ...inputWrapperClasses, 'is-invalid': !!$slots.error || $v.value.$error || error, 'is-valid': valid }"
    )
      .input-prefix-slot(
        v-if="$slots.prefix || prefix"
        @click="prefixClicked"
        :class="{ 'input-truncated-prefix': prefixIsTruncated, 'input-phone-prefix': phonePrefix }"
      )
        slot(name="prefix")
          div(:title="prefixIsTruncated ? prefix : null") {{ computedPrefix }}
      input.input-element(
        :class="{ ...inputClasses }"
        ref="input"
        @focus="focus($event)"
        @blur="blur($event)"
        @input="$emit('input', $event.target.value)"
        @keyup.enter="$emit('enter', $event.target.value)"
        v-bind="inputProps"
      )
      .input-suffix-slot(v-if="$slots.suffix || suffix" @click="suffixClicked")
        slot(name="suffix")
          div {{ suffix }}
    .form-text.text-danger(v-if="$slots.error || error")
      slot(name="error") {{ errorText }}
    .form-text(v-if="($slots.helpText && !$slots.error) || helpText")
      slot(name="helpText") {{ helpText }}
</template>

<script>
  import { UilExclamationCircle } from '@iconscout/vue-unicons';
  import { requiredIf } from 'vuelidate/lib/validators';
  import { TYPES } from './constants';
  import designSystemMixin from '../mixins/designSystem';

  const isValidType = (type) => TYPES.includes(type);

  export default {
    name: 'OmInput',
    components: {
      UilExclamationCircle,
    },
    mixins: [designSystemMixin],
    props: {
      id: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
        default: null,
      },
      valid: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return isValidType(value);
        },
      },
      label: {
        type: String,
        default: null,
      },
      sideLabel: {
        type: String,
        default: 'default',
        options: ['default', 'auto', 'half'],
        validator: (value) => {
          return ['default', 'auto', 'half'].includes(value.toLowerCase());
        },
      },
      placeholder: {
        type: [String, Number],
        default: null,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorText: {
        type: String,
        default: null,
      },
      helpText: {
        type: String,
        default: null,
      },
      prefix: {
        type: String,
        default: null,
      },
      suffix: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: true,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      maxlength: {
        type: Number,
      },
      minlength: {
        type: Number,
      },
      phonePrefix: {
        type: Boolean,
        default: false,
      },
    },
    validations: {
      value: {
        required: requiredIf(function () {
          return this.required;
        }),
        isValidValue(value) {
          value = value || '';
          if (this.type === 'text') {
            return value.trim().length;
          }
          if (this.type === 'password') {
            return value.trim().length >= 8;
          }
          if (this.type === 'email') {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value.trim(),
            );
          }
          if (this.type === 'number') {
            return /^[0-9]+$/.test(value);
          }
          if (this.type === 'url') {
            return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
              value.trim(),
            );
          }
        },
      },
    },
    data() {
      return {
        focusState: false,
        unTruncatedPrefixLength: 25,
      };
    },
    computed: {
      computedPrefix() {
        return this.prefixIsTruncated
          ? `...${this.prefix.slice(-this.unTruncatedPrefixLength)}`
          : this.prefix;
      },
      prefixIsTruncated() {
        return this.prefix.length >= this.unTruncatedPrefixLength;
      },
      inputProps() {
        const {
          label,
          sideLabel,
          large,
          small,
          invalid,
          valid,
          error,
          errorText,
          helpText,
          designSystem,
          ...inputProps
        } = this.$props;
        return inputProps;
      },
      wrapperClasses() {
        return {
          'input-element-sm': this.small,
          'input-element-lg': this.large,
        };
      },
      inputWrapperClasses() {
        return {
          disabled: this.disabled,
          focus: this.focusState,
          'design-system': this.designSystem,
          [`type-${this.type}`]: this.type,
          'prefix-slot-used': this.$slots.prefix || this.prefix,
          'suffix-slot-used': this.$slots.suffix || this.suffix,
        };
      },
      inputClasses() {
        return {
          'text-right': this.type === 'number',
        };
      },
      isDefaultSideLabel() {
        return this.sideLabel === 'default';
      },
      labelClasses() {
        return {
          'col-form-label': !this.isDefaultSideLabel,
          'col-auto': this.sideLabel === 'auto',
          col: this.sideLabel === 'half',
          'py-2': this.small && !this.isDefaultSideLabel,
          'py-3': this.large && !this.isDefaultSideLabel,
        };
      },
    },

    methods: {
      focus(event) {
        this.$emit('focus', event);
        this.focusState = !this.focusState;
      },
      blur(event) {
        this.$emit('blur', { event, ref: this });
        this.focusState = !this.focusState;
      },
      validate() {
        this.$v.$touch();
        this.$emit('input:validation', !this.$v.$invalid);
        return !this.$v.$invalid;
      },
      suffixClicked() {
        this.$refs.input.focus();
        this.$emit('input:suffixClicked');
      },
      prefixClicked() {
        this.$refs.input.focus();
        this.$emit('input:prefixClicked');
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  @import '@/sass/components/_forms.sass'
  $input-height: 40px
  $input-height-sm: 32px
  $input-height-lg: 52px
  .input-element
    height: calc(#{$input-height} - 2px)
    line-height: 2em
  .input-element-sm .input-element
    height: calc(#{$input-height-sm} - 2px)
    line-height: 2em
  .input-element-lg .input-element
    height: calc(#{$input-height-lg} - 2px)
    line-height: 2em

  .input-element-wrapper.prefix-slot-used .input-element
    padding-left: 0.25rem

  .input-element-wrapper.suffix-slot-used
    .input-element
      padding-right: 0.25rem
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

      &[type=number]
        -moz-appearance: textfield

  .input-prefix-slot
    padding-left: 0.75rem
    color: $om-gray-700
  .input-suffix-slot
    padding-right: 0.75rem
    color: $om-gray-700
    padding-top: 2px
  .input-prefix-slot,
  .input-suffix-slot
    white-space: nowrap
  .input-phone-prefix
    background: #E9ECEF
    padding: 0.52rem 1rem
    border-right: 1px solid #D5D9DD
    border-radius: 3px 0px 0px 3px
  .input-label
    color: $om-gray-700
    font-size: 12px
    line-height: 1.5rem
    padding-right: .75rem
    margin-bottom: 0
    padding-top: 0.5rem
    align-items: center
    &:empty
      padding: 0
  @media screen and (max-width: 1199px)
    .input-phone-prefix
      padding: 0.8rem 1rem
  @media screen and (max-width: 992px)
    .input-phone-prefix
      padding: 1rem 1rem
</style>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'

  .text-danger
    color: $om-alert-red-500 !important
</style>
